var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import devWarning from '../_util/devWarning';
const Avatar = props => {
    const [scale, setScale] = React.useState(1);
    const [mounted, setMounted] = React.useState(false);
    const [isImgExist, setIsImgExist] = React.useState(true);
    const avatarNodeRef = React.useRef();
    const avatarChildrenRef = React.useRef();
    let lastChildrenWidth;
    let lastNodeWidth;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const setScaleParam = () => {
        if (!avatarChildrenRef.current || !avatarNodeRef.current) {
            return;
        }
        const childrenWidth = avatarChildrenRef.current.offsetWidth; // offsetWidth avoid affecting be transform scale
        const nodeWidth = avatarNodeRef.current.offsetWidth;
        const { gap = 4 } = props;
        // denominator is 0 is no meaning
        if (childrenWidth !== 0 &&
            nodeWidth !== 0 &&
            (lastChildrenWidth !== childrenWidth || lastNodeWidth !== nodeWidth)) {
            lastChildrenWidth = childrenWidth;
            lastNodeWidth = nodeWidth;
        }
        if (gap * 2 < nodeWidth) {
            setScale(nodeWidth - gap * 2 < childrenWidth ? (nodeWidth - gap * 2) / childrenWidth : 1);
        }
    };
    React.useEffect(() => {
        setScaleParam();
        setMounted(true);
    }, []);
    React.useEffect(() => {
        setIsImgExist(true);
        setScale(1);
    }, [props.src]);
    React.useEffect(() => {
        setScaleParam();
    }, [props.children, props.gap]);
    React.useEffect(() => {
        if (props.children) {
            setScaleParam();
        }
    }, [isImgExist]);
    const handleImgLoadError = () => {
        const { onError } = props;
        const errorFlag = onError ? onError() : undefined;
        if (errorFlag !== false) {
            setIsImgExist(false);
        }
    };
    const { prefixCls: customizePrefixCls, shape, size, src, srcSet, icon, className, alt, draggable } = props, others = __rest(props, ["prefixCls", "shape", "size", "src", "srcSet", "icon", "className", "alt", "draggable"]);
    if (process.env.NODE_ENV !== 'production')
        devWarning(!(typeof icon === 'string' && icon.length > 2), 'Avatar', `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`);
    const prefixCls = getPrefixCls('avatar', customizePrefixCls);
    const sizeCls = classNames({
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-sm`]: size === 'small',
    });
    const classString = classNames(prefixCls, className, sizeCls, {
        [`${prefixCls}-${shape}`]: shape,
        [`${prefixCls}-image`]: src && isImgExist,
        [`${prefixCls}-icon`]: icon,
    });
    const sizeStyle = typeof size === 'number'
        ? {
            width: size,
            height: size,
            lineHeight: `${size}px`,
            fontSize: icon ? size / 2 : 18,
        }
        : {};
    let { children } = props;
    if (src && isImgExist) {
        children = (<img src={src} draggable={draggable} srcSet={srcSet} onError={handleImgLoadError} alt={alt}/>);
    }
    else if (icon) {
        children = icon;
    }
    else {
        const childrenNode = avatarChildrenRef.current;
        if (childrenNode || scale !== 1) {
            const transformString = `scale(${scale}) translateX(-50%)`;
            const childrenStyle = {
                msTransform: transformString,
                WebkitTransform: transformString,
                transform: transformString,
            };
            const sizeChildrenStyle = typeof size === 'number'
                ? {
                    lineHeight: `${size}px`,
                }
                : {};
            children = (<span className={`${prefixCls}-string`} ref={(node) => {
                avatarChildrenRef.current = node;
            }} style={Object.assign(Object.assign({}, sizeChildrenStyle), childrenStyle)}>
          {children}
        </span>);
        }
        else {
            const childrenStyle = {};
            if (!mounted) {
                childrenStyle.opacity = 0;
            }
            children = (<span className={`${prefixCls}-string`} style={{ opacity: 0 }} ref={(node) => {
                avatarChildrenRef.current = node;
            }}>
          {children}
        </span>);
        }
    }
    // The event is triggered twice from bubbling up the DOM tree.
    // see https://codesandbox.io/s/kind-snow-9lidz
    delete others.onError;
    delete others.gap;
    return (<span {...others} style={Object.assign(Object.assign({}, sizeStyle), others.style)} className={classString} ref={(node) => {
        avatarNodeRef.current = node;
    }}>
      {children}
    </span>);
};
Avatar.defaultProps = {
    shape: 'circle',
    size: 'default',
};
export default Avatar;
