import * as React from 'react';
import classNames from 'classnames';
import Col from '../grid/col';
import { FormContext } from './context';
const FormItemLabel = ({ prefixCls, label, htmlFor, labelCol, labelAlign, colon, required, }) => {
    if (!label)
        return null;
    return (<FormContext.Consumer key="label">
      {({ vertical, labelAlign: contextLabelAlign, labelCol: contextLabelCol, colon: contextColon, }) => {
        const mergedLabelCol = labelCol || contextLabelCol || {};
        const mergedLabelAlign = labelAlign || contextLabelAlign;
        const labelClsBasic = `${prefixCls}-item-label`;
        const labelColClassName = classNames(labelClsBasic, mergedLabelAlign === 'left' && `${labelClsBasic}-left`, mergedLabelCol.className);
        let labelChildren = label;
        // Keep label is original where there should have no colon
        const computedColon = colon === true || (contextColon !== false && colon !== false);
        const haveColon = computedColon && !vertical;
        // Remove duplicated user input colon
        if (haveColon && typeof label === 'string' && label.trim() !== '') {
            labelChildren = label.replace(/[:|：]\s*$/, '');
        }
        const labelClassName = classNames({
            [`${prefixCls}-item-required`]: required,
            [`${prefixCls}-item-no-colon`]: !computedColon,
        });
        return (<Col {...mergedLabelCol} className={labelColClassName}>
            <label htmlFor={htmlFor} className={labelClassName} title={typeof label === 'string' ? label : ''}>
              {labelChildren}
            </label>
          </Col>);
    }}
    </FormContext.Consumer>);
};
export default FormItemLabel;
