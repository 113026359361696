import * as React from 'react';
import Notification from 'rc-notification';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import createUseNotification from './hooks/useNotification';
const notificationInstance = {};
let defaultDuration = 4.5;
let defaultTop = 24;
let defaultBottom = 24;
let defaultPrefixCls = 'ant-notification';
let defaultPlacement = 'topRight';
let defaultGetContainer;
let defaultCloseIcon;
let rtl = false;
function setNotificationConfig(options) {
    const { duration, placement, bottom, top, getContainer, closeIcon, prefixCls } = options;
    if (prefixCls !== undefined) {
        defaultPrefixCls = prefixCls;
    }
    if (duration !== undefined) {
        defaultDuration = duration;
    }
    if (placement !== undefined) {
        defaultPlacement = placement;
    }
    else if (options.rtl) {
        defaultPlacement = 'topLeft';
    }
    if (bottom !== undefined) {
        defaultBottom = bottom;
    }
    if (top !== undefined) {
        defaultTop = top;
    }
    if (getContainer !== undefined) {
        defaultGetContainer = getContainer;
    }
    if (closeIcon !== undefined) {
        defaultCloseIcon = closeIcon;
    }
    if (options.rtl !== undefined) {
        rtl = options.rtl;
    }
}
function getPlacementStyle(placement, top = defaultTop, bottom = defaultBottom) {
    let style;
    switch (placement) {
        case 'topLeft':
            style = {
                left: 0,
                top,
                bottom: 'auto',
            };
            break;
        case 'topRight':
            style = {
                right: 0,
                top,
                bottom: 'auto',
            };
            break;
        case 'bottomLeft':
            style = {
                left: 0,
                top: 'auto',
                bottom,
            };
            break;
        default:
            style = {
                right: 0,
                top: 'auto',
                bottom,
            };
            break;
    }
    return style;
}
function getNotificationInstance(args, callback) {
    const { placement = defaultPlacement, top, bottom, getContainer = defaultGetContainer, closeIcon = defaultCloseIcon, } = args;
    const outerPrefixCls = args.prefixCls || defaultPrefixCls;
    const prefixCls = `${outerPrefixCls}-notice`;
    const cacheKey = `${outerPrefixCls}-${placement}`;
    const cacheInstance = notificationInstance[cacheKey];
    if (cacheInstance) {
        Promise.resolve(cacheInstance).then(instance => {
            callback({ prefixCls, instance });
        });
        return;
    }
    const closeIconToRender = (<span className={`${outerPrefixCls}-close-x`}>
      {closeIcon || <CloseOutlined className={`${outerPrefixCls}-close-icon`}/>}
    </span>);
    const notificationClass = classNames(`${outerPrefixCls}-${placement}`, {
        [`${outerPrefixCls}-rtl`]: rtl === true,
    });
    notificationInstance[cacheKey] = new Promise(resolve => {
        Notification.newInstance({
            prefixCls: outerPrefixCls,
            className: notificationClass,
            style: getPlacementStyle(placement, top, bottom),
            getContainer,
            closeIcon: closeIconToRender,
        }, notification => {
            resolve(notification);
            callback({
                prefixCls,
                instance: notification,
            });
        });
    });
}
const typeToIcon = {
    success: CheckCircleOutlined,
    info: InfoCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
};
function getRCNoticeProps(args, prefixCls) {
    const duration = args.duration === undefined ? defaultDuration : args.duration;
    let iconNode = null;
    if (args.icon) {
        iconNode = <span className={`${prefixCls}-icon`}>{args.icon}</span>;
    }
    else if (args.type) {
        iconNode = React.createElement(typeToIcon[args.type] || null, {
            className: `${prefixCls}-icon ${prefixCls}-icon-${args.type}`,
        });
    }
    const autoMarginTag = !args.description && iconNode ? (<span className={`${prefixCls}-message-single-line-auto-margin`}/>) : null;
    return {
        content: (<div className={iconNode ? `${prefixCls}-with-icon` : ''}>
        {iconNode}
        <div className={`${prefixCls}-message`}>
          {autoMarginTag}
          {args.message}
        </div>
        <div className={`${prefixCls}-description`}>{args.description}</div>
        {args.btn ? <span className={`${prefixCls}-btn`}>{args.btn}</span> : null}
      </div>),
        duration,
        closable: true,
        onClose: args.onClose,
        onClick: args.onClick,
        key: args.key,
        style: args.style || {},
        className: args.className,
    };
}
const api = {
    open: (args) => {
        getNotificationInstance(args, ({ prefixCls, instance }) => {
            instance.notice(getRCNoticeProps(args, prefixCls));
        });
    },
    close(key) {
        Object.keys(notificationInstance).forEach(cacheKey => Promise.resolve(notificationInstance[cacheKey]).then(instance => {
            instance.removeNotice(key);
        }));
    },
    config: setNotificationConfig,
    destroy() {
        Object.keys(notificationInstance).forEach(cacheKey => {
            Promise.resolve(notificationInstance[cacheKey]).then(instance => {
                instance.destroy();
            });
            delete notificationInstance[cacheKey]; // lgtm[js/missing-await]
        });
    },
};
['success', 'info', 'warning', 'error'].forEach(type => {
    api[type] = (args) => api.open(Object.assign(Object.assign({}, args), { type }));
});
api.warn = api.warning;
api.useNotification = createUseNotification(getNotificationInstance, getRCNoticeProps);
export default api;
