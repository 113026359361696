var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ListContext } from './index';
import { Col } from '../grid';
import { ConfigContext } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
export const Meta = (_a) => {
    var { prefixCls: customizePrefixCls, className, avatar, title, description } = _a, others = __rest(_a, ["prefixCls", "className", "avatar", "title", "description"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('list', customizePrefixCls);
    const classString = classNames(`${prefixCls}-item-meta`, className);
    const content = (<div className={`${prefixCls}-item-meta-content`}>
      {title && <h4 className={`${prefixCls}-item-meta-title`}>{title}</h4>}
      {description && <div className={`${prefixCls}-item-meta-description`}>{description}</div>}
    </div>);
    return (<div {...others} className={classString}>
      {avatar && <div className={`${prefixCls}-item-meta-avatar`}>{avatar}</div>}
      {(title || description) && content}
    </div>);
};
const Item = (_a) => {
    var { prefixCls: customizePrefixCls, children, actions, extra, className, colStyle } = _a, others = __rest(_a, ["prefixCls", "children", "actions", "extra", "className", "colStyle"]);
    const { grid, itemLayout } = React.useContext(ListContext);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const isItemContainsTextNodeAndNotSingular = () => {
        let result;
        React.Children.forEach(children, (element) => {
            if (typeof element === 'string') {
                result = true;
            }
        });
        return result && React.Children.count(children) > 1;
    };
    const isFlexMode = () => {
        if (itemLayout === 'vertical') {
            return !!extra;
        }
        return !isItemContainsTextNodeAndNotSingular();
    };
    const prefixCls = getPrefixCls('list', customizePrefixCls);
    const actionsContent = actions && actions.length > 0 && (<ul className={`${prefixCls}-item-action`} key="actions">
      {actions.map((action, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={`${prefixCls}-item-action-${i}`}>
          {action}
          {i !== actions.length - 1 && <em className={`${prefixCls}-item-action-split`}/>}
        </li>))}
    </ul>);
    const Element = grid ? 'div' : 'li';
    const itemChildren = (<Element {...others} // `li` element `onCopy` prop args is not same as `div`
     className={classNames(`${prefixCls}-item`, className, {
        [`${prefixCls}-item-no-flex`]: !isFlexMode(),
    })}>
      {itemLayout === 'vertical' && extra
        ? [
            <div className={`${prefixCls}-item-main`} key="content">
              {children}
              {actionsContent}
            </div>,
            <div className={`${prefixCls}-item-extra`} key="extra">
              {extra}
            </div>,
        ]
        : [children, actionsContent, cloneElement(extra, { key: 'extra' })]}
    </Element>);
    return grid ? (<Col flex={1} style={colStyle}>
      {itemChildren}
    </Col>) : (itemChildren);
};
Item.Meta = Meta;
export default Item;
