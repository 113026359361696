var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import DownOutlined from '@ant-design/icons/DownOutlined';
import DropDown from '../dropdown/dropdown';
import { ConfigContext } from '../config-provider';
const BreadcrumbItem = (_a) => {
    var { prefixCls: customizePrefixCls, separator, children, overlay, dropdownProps } = _a, restProps = __rest(_a, ["prefixCls", "separator", "children", "overlay", "dropdownProps"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);
    /**
     * if overlay is have
     * Wrap a DropDown
     */
    const renderBreadcrumbNode = (breadcrumbItem) => {
        if (overlay) {
            return (<DropDown overlay={overlay} placement="bottomCenter" {...dropdownProps}>
          <span className={`${prefixCls}-overlay-link`}>
            {breadcrumbItem}
            <DownOutlined />
          </span>
        </DropDown>);
        }
        return breadcrumbItem;
    };
    let link;
    if ('href' in restProps) {
        link = (<a className={`${prefixCls}-link`} {...restProps}>
        {children}
      </a>);
    }
    else {
        link = (<span className={`${prefixCls}-link`} {...restProps}>
        {children}
      </span>);
    }
    // wrap to dropDown
    link = renderBreadcrumbNode(link);
    if (children) {
        return (<span>
        {link}
        {separator && separator !== '' && (<span className={`${prefixCls}-separator`}>{separator}</span>)}
      </span>);
    }
    return null;
};
BreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
export default BreadcrumbItem;
