import * as React from 'react';
import RcTreeSelect, { TreeNode, SHOW_ALL, SHOW_PARENT, SHOW_CHILD, } from 'rc-tree-select';
import classNames from 'classnames';
import omit from 'omit.js';
import { ConfigConsumer } from '../config-provider';
import devWarning from '../_util/devWarning';
import getIcons from '../select/utils/iconUtil';
import renderSwitcherIcon from '../tree/utils/iconUtil';
import SizeContext from '../config-provider/SizeContext';
class TreeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.renderTreeSelect = ({ getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, }) => {
            const { prefixCls: customizePrefixCls, size: customizeSize, className, treeCheckable, multiple, listHeight = 256, listItemHeight = 26, notFoundContent, switcherIcon, treeLine, getPopupContainer, dropdownClassName, bordered, treeIcon = false, } = this.props;
            const prefixCls = getPrefixCls('select', customizePrefixCls);
            const treePrefixCls = getPrefixCls('select-tree', customizePrefixCls);
            const treeSelectPrefixCls = getPrefixCls('tree-select', customizePrefixCls);
            const mergedDropdownClassName = classNames(dropdownClassName, `${treeSelectPrefixCls}-dropdown`, {
                [`${treeSelectPrefixCls}-dropdown-rtl`]: direction === 'rtl',
            });
            const isMultiple = !!(treeCheckable || multiple);
            // ===================== Icons =====================
            const { suffixIcon, itemIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, this.props), { multiple: isMultiple }));
            // ===================== Empty =====================
            let mergedNotFound;
            if (notFoundContent !== undefined) {
                mergedNotFound = notFoundContent;
            }
            else {
                mergedNotFound = renderEmpty('Select');
            }
            // ==================== Render =====================
            const selectProps = omit(this.props, [
                'prefixCls',
                'suffixIcon',
                'itemIcon',
                'removeIcon',
                'clearIcon',
                'switcherIcon',
                'size',
                'bordered',
            ]);
            return (<SizeContext.Consumer>
        {size => {
                const mergedSize = customizeSize || size;
                const mergedClassName = classNames(!customizePrefixCls && treeSelectPrefixCls, {
                    [`${prefixCls}-lg`]: mergedSize === 'large',
                    [`${prefixCls}-sm`]: mergedSize === 'small',
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                    [`${prefixCls}-borderless`]: !bordered,
                }, className);
                return (<RcTreeSelect virtual={virtual} dropdownMatchSelectWidth={dropdownMatchSelectWidth} {...selectProps} ref={this.selectRef} prefixCls={prefixCls} className={mergedClassName} listHeight={listHeight} listItemHeight={listItemHeight} treeCheckable={treeCheckable ? (<span className={`${prefixCls}-tree-checkbox-inner`}/>) : (treeCheckable)} inputIcon={suffixIcon} menuItemSelectedIcon={itemIcon} removeIcon={removeIcon} clearIcon={clearIcon} switcherIcon={(nodeProps) => renderSwitcherIcon(treePrefixCls, switcherIcon, treeLine, nodeProps)} showTreeIcon={treeIcon} notFoundContent={mergedNotFound} getPopupContainer={getPopupContainer || getContextPopupContainer} treeMotion={null} dropdownClassName={mergedDropdownClassName}/>);
            }}
      </SizeContext.Consumer>);
        };
        if (process.env.NODE_ENV !== 'production')
            devWarning(props.multiple !== false || !props.treeCheckable, 'TreeSelect', '`multiple` will alway be `true` when `treeCheckable` is true');
    }
    focus() {
        if (this.selectRef.current) {
            this.selectRef.current.focus();
        }
    }
    blur() {
        if (this.selectRef.current) {
            this.selectRef.current.blur();
        }
    }
    render() {
        return <ConfigConsumer>{this.renderTreeSelect}</ConfigConsumer>;
    }
}
TreeSelect.TreeNode = TreeNode;
TreeSelect.SHOW_ALL = SHOW_ALL;
TreeSelect.SHOW_PARENT = SHOW_PARENT;
TreeSelect.SHOW_CHILD = SHOW_CHILD;
TreeSelect.defaultProps = {
    transitionName: 'slide-up',
    choiceTransitionName: 'zoom',
    bordered: true,
};
export { TreeNode };
export default TreeSelect;
