import * as React from 'react';
import debounce from 'lodash/debounce';
import SlickCarousel from '@ant-design/react-slick';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
export default class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.saveSlick = (node) => {
            this.slick = node;
        };
        this.onWindowResized = () => {
            // Fix https://github.com/ant-design/ant-design/issues/2550
            const { autoplay } = this.props;
            if (autoplay && this.slick && this.slick.innerSlider && this.slick.innerSlider.autoPlay) {
                this.slick.innerSlider.autoPlay();
            }
        };
        this.renderCarousel = ({ getPrefixCls, direction }) => {
            var _a;
            const props = Object.assign({}, this.props);
            if (props.effect === 'fade') {
                props.fade = true;
            }
            const prefixCls = getPrefixCls('carousel', props.prefixCls);
            const dotsClass = 'slick-dots';
            const dotPosition = this.getDotPosition();
            props.vertical = dotPosition === 'left' || dotPosition === 'right';
            const enableDots = !!props.dots;
            const dsClass = classNames(dotsClass, `${dotsClass}-${dotPosition || 'bottom'}`, typeof props.dots === 'boolean' ? false : (_a = props.dots) === null || _a === void 0 ? void 0 : _a.className);
            const className = classNames(prefixCls, {
                [`${prefixCls}-rtl`]: direction === 'rtl',
                [`${prefixCls}-vertical`]: props.vertical,
            });
            return (<div className={className}>
        <SlickCarousel ref={this.saveSlick} {...props} dots={enableDots} dotsClass={dsClass}/>
      </div>);
        };
        this.onWindowResized = debounce(this.onWindowResized, 500, {
            leading: false,
        });
    }
    componentDidMount() {
        const { autoplay } = this.props;
        if (autoplay) {
            window.addEventListener('resize', this.onWindowResized);
        }
        // https://github.com/ant-design/ant-design/issues/7191
        this.innerSlider = this.slick && this.slick.innerSlider;
    }
    componentDidUpdate(prevProps) {
        if (React.Children.count(this.props.children) !== React.Children.count(prevProps.children)) {
            this.goTo(this.props.initialSlide || 0, false);
        }
    }
    componentWillUnmount() {
        const { autoplay } = this.props;
        if (autoplay) {
            window.removeEventListener('resize', this.onWindowResized);
            this.onWindowResized.cancel();
        }
    }
    getDotPosition() {
        const { dotPosition = 'bottom' } = this.props;
        return dotPosition;
    }
    next() {
        this.slick.slickNext();
    }
    prev() {
        this.slick.slickPrev();
    }
    goTo(slide, dontAnimate = false) {
        this.slick.slickGoTo(slide, dontAnimate);
    }
    render() {
        return <ConfigConsumer>{this.renderCarousel}</ConfigConsumer>;
    }
}
Carousel.defaultProps = {
    dots: true,
    arrows: false,
    draggable: false,
};
