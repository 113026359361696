// TODO: 4.0 - codemod should help to change `filterOption` to support node props.
import * as React from 'react';
import omit from 'omit.js';
import classNames from 'classnames';
import RcSelect, { Option, OptGroup } from 'rc-select';
import { OptionProps } from 'rc-select/lib/Option';
import { ConfigConsumer } from '../config-provider';
import getIcons from './utils/iconUtil';
import SizeContext from '../config-provider/SizeContext';
export { OptionProps };
// We still use class here since `forwardRef` not support generic in typescript
class Select extends React.Component {
    constructor() {
        super(...arguments);
        this.selectRef = React.createRef();
        this.focus = () => {
            if (this.selectRef.current) {
                this.selectRef.current.focus();
            }
        };
        this.blur = () => {
            if (this.selectRef.current) {
                this.selectRef.current.blur();
            }
        };
        this.getMode = () => {
            const { mode } = this.props;
            if (mode === 'combobox') {
                return undefined;
            }
            if (mode === Select.SECRET_COMBOBOX_MODE_DO_NOT_USE) {
                return 'combobox';
            }
            return mode;
        };
        this.renderSelect = ({ getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, }) => {
            const { prefixCls: customizePrefixCls, notFoundContent, className, size: customizeSize, listHeight = 256, listItemHeight = 24, getPopupContainer, dropdownClassName, bordered, } = this.props;
            const prefixCls = getPrefixCls('select', customizePrefixCls);
            const mode = this.getMode();
            const isMultiple = mode === 'multiple' || mode === 'tags';
            // ===================== Empty =====================
            let mergedNotFound;
            if (notFoundContent !== undefined) {
                mergedNotFound = notFoundContent;
            }
            else if (mode === 'combobox') {
                mergedNotFound = null;
            }
            else {
                mergedNotFound = renderEmpty('Select');
            }
            // ===================== Icons =====================
            const { suffixIcon, itemIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, this.props), { multiple: isMultiple }));
            const selectProps = omit(this.props, [
                'prefixCls',
                'suffixIcon',
                'itemIcon',
                'removeIcon',
                'clearIcon',
                'size',
                'bordered',
            ]);
            const rcSelectRtlDropDownClassName = classNames(dropdownClassName, {
                [`${prefixCls}-dropdown-${direction}`]: direction === 'rtl',
            });
            return (<SizeContext.Consumer>
        {size => {
                const mergedSize = customizeSize || size;
                const mergedClassName = classNames(className, {
                    [`${prefixCls}-lg`]: mergedSize === 'large',
                    [`${prefixCls}-sm`]: mergedSize === 'small',
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                    [`${prefixCls}-borderless`]: !bordered,
                });
                return (<RcSelect ref={this.selectRef} virtual={virtual} dropdownMatchSelectWidth={dropdownMatchSelectWidth} {...selectProps} listHeight={listHeight} listItemHeight={listItemHeight} mode={mode} prefixCls={prefixCls} direction={direction} inputIcon={suffixIcon} menuItemSelectedIcon={itemIcon} removeIcon={removeIcon} clearIcon={clearIcon} notFoundContent={mergedNotFound} className={mergedClassName} getPopupContainer={getPopupContainer || getContextPopupContainer} dropdownClassName={rcSelectRtlDropDownClassName}/>);
            }}
      </SizeContext.Consumer>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderSelect}</ConfigConsumer>;
    }
}
Select.Option = Option;
Select.OptGroup = OptGroup;
Select.SECRET_COMBOBOX_MODE_DO_NOT_USE = 'SECRET_COMBOBOX_MODE_DO_NOT_USE';
Select.defaultProps = {
    transitionName: 'slide-up',
    choiceTransitionName: 'zoom',
    bordered: true,
};
export default Select;
