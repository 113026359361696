import * as React from 'react';
import raf from 'raf';
import { useForm as useRcForm } from 'rc-field-form';
import scrollIntoView from 'scroll-into-view-if-needed';
/**
 * Always debounce error to avoid [error -> null -> error] blink
 */
export function useCacheErrors(errors, changeTrigger, directly) {
    const cacheRef = React.useRef({
        errors,
        visible: !!errors.length,
    });
    const [, forceUpdate] = React.useState({});
    const update = (newErrors) => {
        const prevVisible = cacheRef.current.visible;
        const newVisible = !!newErrors.length;
        const prevErrors = cacheRef.current.errors;
        cacheRef.current.errors = newErrors;
        cacheRef.current.visible = newVisible;
        if (prevVisible !== newVisible) {
            changeTrigger(newVisible);
        }
        else if (prevErrors.length !== newErrors.length ||
            prevErrors.some((prevErr, index) => prevErr !== newErrors[index])) {
            forceUpdate({});
        }
    };
    React.useEffect(() => {
        if (!directly) {
            const timeout = setTimeout(() => {
                update(errors);
            }, 10);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [errors]);
    if (directly) {
        update(errors);
    }
    return [cacheRef.current.visible, cacheRef.current.errors];
}
export function toArray(candidate) {
    if (candidate === undefined || candidate === false)
        return [];
    return Array.isArray(candidate) ? candidate : [candidate];
}
export function getFieldId(namePath, formName) {
    if (!namePath.length)
        return undefined;
    const mergedId = namePath.join('_');
    return formName ? `${formName}_${mergedId}` : mergedId;
}
export function useForm(form) {
    const [rcForm] = useRcForm();
    const wrapForm = React.useMemo(() => form || Object.assign(Object.assign({}, rcForm), { __INTERNAL__: {}, scrollToField: (name, options = {}) => {
            const namePath = toArray(name);
            const fieldId = getFieldId(namePath, wrapForm.__INTERNAL__.name);
            const node = fieldId ? document.getElementById(fieldId) : null;
            if (node) {
                scrollIntoView(node, Object.assign({ scrollMode: 'if-needed', block: 'nearest' }, options));
            }
        } }), [form, rcForm]);
    return [wrapForm];
}
export function useFrameState(defaultValue) {
    const [value, setValue] = React.useState(defaultValue);
    const frameRef = React.useRef(null);
    const batchRef = React.useRef([]);
    const destroyRef = React.useRef(false);
    React.useEffect(() => () => {
        destroyRef.current = true;
        raf.cancel(frameRef.current);
    }, []);
    function setFrameValue(updater) {
        if (destroyRef.current) {
            return;
        }
        if (frameRef.current === null) {
            batchRef.current = [];
            frameRef.current = raf(() => {
                frameRef.current = null;
                setValue(prevValue => {
                    let current = prevValue;
                    batchRef.current.forEach(func => {
                        current = func(current);
                    });
                    return current;
                });
            });
        }
        batchRef.current.push(updater);
    }
    return [value, setFrameValue];
}
